import React from 'react';
import classNames from 'classnames';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'gatsby';

import { UqLayoutV2, UqContainer, UqCustomerLogosV2, UqRequestDemoBanner, UqFooterV2, UqAppBarV2, UqNavbarV2 } from '@uq-components';
import { UQVideoTestimonialEventCards, UQEventCards, UQHeaderSection, UQPageContentRow, UQCustomerTestimonial } from '../../components';
import SEO from '../../components/seo';
import { CUSTOMERS_PAGE_STORIES, FEATURED_STORIES, VIDEO_TESTIMONIALS } from '../../content/v2/customers';

import * as styles from './index.module.scss';
import * as customerStyles from './customer.module.scss';

export default function UqCustomersPage() {
  return (
    <UqLayoutV2>
      <UqAppBarV2>
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className={customerStyles.root}>
        <div className={classNames('master-container', styles.customersPage)}>
          <SEO
            title='Customer Testimonials and Case Studies'
            description={
              `Learn how category-leading companies are relying on unitQ to drive
          product quality excellence, elevate user satisfaction, and accelerate growth.`
            }
          />
          <Container fluid className={classNames('px-0', styles.headerContainer)}>
            <Row noGutters className={classNames('justify-content-center', styles.headerSectionRow)}>
              <Col className={classNames(styles.heroContainer, 'justify-content-center')}>
                <UQHeaderSection
                  classes={{
                    root: styles.headerSectionRoot,
                    contentSection: styles.headerSectionContentSection,
                    preTitleOverride: styles.headerSectionModulePreTitle,
                    subTitleOverride: styles.headerSectionModuleSubTitle,
                  }}
                  preTitle="Customer Stories"
                  title="Zendesk"
                  subtitle={`Zendesk is not only a valued customer
                    but is now a strategic investor in the unitQ customer feedback analytics platform`
                  }
                  imageUrl="/images/v2/customers/zendesk.jpg"
                >
                  <div className="new-uq-theme mb-4">
                    <Link to="/blog/unitq-customer-zendesk-becomes-strategic-unitq-investor/" className="link-no-style">
                      <Button className="extra-wide" variant="primary">Read Their Story</Button>
                    </Link>
                  </div>
                </UQHeaderSection>
              </Col>
            </Row>
          </Container>
          <UQPageContentRow classes={{ row: styles.featuresContainer }}>
            <UQEventCards
              stretchable
              cards={FEATURED_STORIES}
              title='Featured Stories'
            />
          </UQPageContentRow>
          <UQPageContentRow classes={{ row: styles.featuresContainer }}>
            <UQVideoTestimonialEventCards
              stretchable
              cards={VIDEO_TESTIMONIALS}
              title='Video Testimonials'
            />
          </UQPageContentRow>
          <UQPageContentRow classes={{ row: styles.featuresContainer }}>
            <UQEventCards
              stretchable
              cards={CUSTOMERS_PAGE_STORIES}
              title='Customer Stories'
            />
          </UQPageContentRow>
          <UQPageContentRow classes={{ row: styles.testimonialsContainer }}>
            <UQCustomerTestimonial
              author='Head of Product Operations, Chime'
              imageUrl='/images/plogos/chime.svg'
              text='“unitQ’s insights keep us ahead of the competition and their platform
          makes it really easy to build consensus and action plans across teams.”'
            />
          </UQPageContentRow>
          <UQPageContentRow classes={{ row: styles.testimonialsContainer }}>
            <UqCustomerLogosV2 />
          </UQPageContentRow>
          <UqContainer>
            <UqRequestDemoBanner />
          </UqContainer>
        </div>
      </div>

      <UqFooterV2 />
    </UqLayoutV2>
  );
}
