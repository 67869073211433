import React from 'react';

export const FEATURED_STORIES = [
  {
    title: 'CarGurus',
    description: 'unitQ is transforming how CarGurus analyzes user feedback, helping improve their products and customers’ expectations',
    iconUrl: '/images/v2/customers/cargurus.jpg',
    anchorProps: {
      href: '/customers/cargurus',
      label: 'Read Story',
    },
  },
  {
    title: 'Udemy',
    description: 'unitQ provides Udemy actionable insights to transform student, instructor feedback into exceptional user experiences',
    iconUrl: '/images/v2/customers/udemy.png',
    anchorProps: {
      href: '/customers/udemy',
      label: 'Read Story',
    },
  },
  {
    title: 'Bumble',
    description: 'How unitQ enabled Bumble to harness the power of real-time user feedback',
    iconUrl: '/images/v2/customers/bumble.png',
    anchorProps: {
      href: '/blog/how-unitq-enabled-bumble-to-harness-the-power-of-real-time-user-feedback/',
      label: 'Read Story',
    },
  },
];

export const VIDEO_TESTIMONIALS = [
  {
    title: 'Pinterest',
    personName: 'Vani Kumar',
    personTitle: 'Head of Quality',
    iconUrl: '/images/v2/customers/video_pinterest.png',
    anchorProps: {
      external: true,
      href: 'https://player.vimeo.com/video/798938950?h=e5172ae8bd',
      label: 'Watch Video',
    },
  },
  {
    title: 'DailyPay',
    personName: 'Dar Miranda',
    personTitle: 'VP Product MGMT UX Design & Research',
    iconUrl: '/images/v2/customers/video_dailypay.png',
    anchorProps: {
      external: true,
      href: 'https://vimeo.com/798939974',
      label: 'Watch Video',
    },
  },
  {
    title: 'Upwork',
    personName: 'Todd Ranson',
    personTitle: 'Sr. Dir., Customer 360°',
    iconUrl: '/images/v2/customers/video_upwork.png',
    anchorProps: {
      external: true,
      href: 'https://vimeo.com/798939563',
      label: 'Watch Video',
    },
  },
];

export const CUSTOMERS_PAGE_STORIES = [
  {
    title: 'Upwork',
    description: 'unitQ spotlight: Shennaver Canasa of Upwork',
    iconUrl: '/images/v2/customers/upwork.png',
    anchorProps: {
      href: '/blog/unitq-spotlight-shennaver-canasa-of-upwork',
      label: 'Read Story',
    },
  },
  {
    title: 'The Meet Group',
    description: 'The Meet Group Harnesses the Voice of the Customer to Improve Product Quality With unitQ',
    iconUrl: '/images/v2/customers/the-meet-group.png',
    anchorProps: {
      href: '/customers/themeetgroup',
      label: 'Read Story',
    },
  },
  {
    title: 'Cornershop',
    description: 'Actionable Insights from unitQ, Cornershop by Uber Delivers Enhanced Product Quality',
    iconUrl: '/images/v2/customers/corner-shop.png',
    anchorProps: {
      href: '/customers/cornershop',
      label: 'Read Story',
    },
  },
  {
    title: 'AllTrails',
    description: 'AllTrails is the winner of the AppStore Awards, iPhone App of the year.',
    iconUrl: '/images/v2/customers/all-trails.png',
    anchorProps: {
      href: '/blog/celebrating-unitq-customer-alltrails-apples-iphone-app-of-the-year/',
      label: 'Read Story',
    },
  },
  {
    title: 'DailyPay',
    description: 'Banking On User Feedback to Align Internal Teams on Product Quality',
    iconUrl: '/images/v2/customers/daily-pay.png',
    anchorProps: {
      href: '/customers/dailypay',
      label: 'Read Story',
    },
  },
  {
    title: 'Pinterest',
    description: 'unitQ Spotlight: Rodion Gusev of Pinterest',
    iconUrl: '/images/v2/customers/pinterest.png',
    anchorProps: {
      href: '/blog/unitq-spotlight-rodion-gusev-of-pinterest/',
      label: 'Read Story',
    },
  },
  {
    title: 'Udemy',
    description: 'unitQ spotlight: Ciara Courtney of Udemy',
    iconUrl: '/images/v2/customers/udemy-2.png',
    anchorProps: {
      href: '/blog/unitq-spotlight-ciara-courtney-of-udemy',
      label: 'Read Story',
    },
  },
  {
    title: 'Finance',
    description: 'How a leading fintech reduced support ticket volume 35% with unitQ',
    iconUrl: '/images/v2/customers/finance.png',
    anchorProps: {
      href: '/blog/how-a-leading-fintech-reduced-support-ticket-volume-with-unitq',
      label: 'Read Story',
    },
  },
  {
    title: 'Travel',
    description: 'How a lodging and tourism leader revolutionized travel experiences with unitQ',
    iconUrl: '/images/v2/customers/travel.png',
    anchorProps: {
      href: '/blog/how-a-lodging-and-tourism-leader-revolutionized-travel-experiences-with-unitq/',
      label: 'Read Story',
    },
  },
  {
    title: 'Lovoo',
    description: <>LOVOO + unitQ:<br />Bringing a quality experience to the dating scene</>,
    iconUrl: '/images/v2/customers/lovoo.png',
    anchorProps: {
      href: '/customers/lovoo',
      label: 'Read Story',
    },
  },
  {
    title: 'StyleSeat',
    description: <>StyleSeat +unitQ:<br />Leveraging feedback to build products that delight users</>,
    iconUrl: '/images/v2/customers/style-seat.png',
    anchorProps: {
      href: '/customers/styleseat',
      label: 'Read Story',
    },
  },
];
